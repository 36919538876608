<template>
    <div class="view" ref="list">
        <div class="search">
            <div class="input">
                <img src="@/assets/rescueImgs/search.png" alt="">
                <van-field type="text" v-model="keyword" placeholder='姓名/手机号' clearable/>
            </div>
            <div class="allBtn" @click="getList()">搜索</div>
        </div>
        <van-pull-refresh
            v-model="isLoading"
            success-text="刷新成功"
            @refresh="getList()">
            <div class="li" v-for="(item, index) in list" :key="index" @click="handSurveyor(item)">
                <div class=box>
                    <img src="https://www.tc-rescue.com/images/tc_app/app/driver_tx.png" alt=""/>
                    <div class="info">
                        <span>{{ item.name }}</span>
                        <span>{{ item.phone }}</span>
                    </div>
                </div>
                <van-icon name="arrow" class="arrow"/>
            </div>
            <div class="loadMore" v-if="list.length != 0">{{ loadMoreText }}</div>
        </van-pull-refresh>
    </div>
</template>

<script>
import { sms_post } from '@/apis/unicloud/api';
export default {
    data(){
        return {
            list: [],
            keyword: '',
			moreLoading: false,
            loadMoreText: '上拉加载更多',
			morePage: 2,
			isLoading: false,
        }
    },
    created(){
        this.getList();
    },
	mounted(){
		this.$nextTick(()=>{
			window.addEventListener("scroll",this.screenCheck, true);
		})
	},
	beforeDestroy(){
		window.removeEventListener("scroll",this.screenCheck, true)
	},
    methods: {
        getList(page){
            let data = {
                type: 'list',
                page: page ? page : 1,
                limit: 10,
                keyword: this.keyword
            };
            sms_post(data).then((res)=>{
				let list = res.data.data;
                console.log(list, '查勘员列表')
                if(page){
					if(list.length == 0){
						this.loadMoreText = '没有更多了';
					}else{
						this.morePage++;
						this.list = this.list.concat(list);
						this.loadMoreText = '上拉加载更多';
					}
					this.moreLoading = false;
				}else{
					this.list = list;
					this.morePage = 2;
					this.isLoading = false;
				}
            })
        },
        handSurveyor(e){
            let surveyorInfo = JSON.stringify(e);
            sessionStorage.setItem('surveyorInfo', surveyorInfo);
            this.$router.go(-1);
        },
		screenCheck(){
			let scrollH = window.scrollY + window.innerHeight;
			let offsetHeight = this.$refs.list.offsetHeight;
			if(scrollH >= offsetHeight){
				console.log(scrollH,offsetHeight,'页面触底');
				if(!this.moreLoading){
					this.loadMoreList();
				}
			}
		},
		loadMoreList(){
			if(this.list.length > 9){
				this.moreLoading = true;
				this.loadMoreText = '努力加载中...';
				this.getList(this.morePage)
			}else{
				this.loadMoreText = '没有更多了';
			}
		},
    }
}
</script>

<style lang='scss' scoped>
    .view{
        min-height: 100%;
        .van-pull-refresh{
            padding-top: 48px;
        }
        .search{
            position: fixed;
            top: 0;
            width: 100%;
            height: 48px;
            background: #EEEEEE;
            display: flex;
            justify-content: center;
            align-items: center;
            z-index: 99;
            .input{
                flex: 1;
                padding: 0 8px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                font-size: 14px;
                .van-cell{
                    background: #EEEEEE;
                    height: 38px;
                    padding: 0;
                    line-height: 38px;
                }
                img{
                    width: 17px;
                    height: 17px;
                    margin-right: 5px;
                }
            }
            .allBtn{
                width: 50px;
                height: 100%;
                text-align: center;
                font-size: 14px;
                color: var(--theme-color);
                line-height: 48px;
            }
        }
    }
    .li{
        font-size: 14px;
        padding: 15px;
        border-bottom: 1px solid #e4e4e4;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .box{
            display: flex;
            align-items: center;
            img{
                width: 48px;
                height: 48px;
                margin-right: 10px;
            }
            .info{
                height: 48px;
                padding: 4px 0;
                box-sizing: border-box;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
            }
        }
    }
    .loadMore{
        text-align: center;
        color: #999999;
        font-size: 14px;
        padding: 10px; 
    }
</style>
